import './Menu.scss';

import React, { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { Dispatch } from 'redux';

import { useAuthenticator } from '@aws-amplify/ui-react';
import { menuController } from '@ionic/core/components';
import {
  IonAvatar,
  IonButton,
  IonContent,
  IonItem,
  IonLabel,
  IonMenu,
  IonMenuToggle,
  IonToolbar,
} from '@ionic/react';

import { ROUTES } from '../constants';
import {
  setFilterByLevelCanceladas,
  setFilterByLevelVigentes,
  setFilterByRegionCanceladas,
  setFilterByRegionVigentes,
  setFilterByTypeCanceladas,
  setFilterByTypeVigentes,
} from '../features/settings/settingsActions';
import { useAppSelector } from '../hooks';
import { goToTopOfPage } from '../pages/AppTabs';
import { RootState } from '../store';
import IncendiosLogo from '../theme/incendios_logo_sm.svg';
import SenapredLogo from '../theme/senapred_logo.svg';

const appVersion = process.env.REACT_APP_CONDOR_APP_VERSION;

const Menu: React.FC<{ tabPrefix: string; location: string }> = ({
  location,
}) => {
  const { user, signOut } = useAuthenticator((context) => [context.user]);

  const dispatchFilters: Dispatch<any> = useDispatch();
  const locationFilters = window.location;
  const { username } = user;
  const { name, family_name } = (user as any) && user.attributes;
  const history = useHistory();

  useEffect(() => {
    menuController.close();
  }, [location]);

  const performLogout = async () => {
    signOut();
  };

  const {
    filterByTypeCanceladas,
    filterByLevelCanceladas,
    filterByRegionCanceladas,
  } = useAppSelector((state: RootState) => state.settings);

  const {
    filterByTypeVigentes,
    filterByLevelVigentes,
    filterByRegionVigentes,
  } = useAppSelector((state: RootState) => state.settings);

  const changePage = (path: string) => {
    if (
      locationFilters.pathname === ROUTES.cancelledAlerts.path &&
      path === ROUTES.cancelledAlerts.path &&
      (filterByTypeCanceladas.length ||
        filterByLevelCanceladas.length ||
        filterByRegionCanceladas.length)
    ) {
      dispatchFilters(setFilterByLevelCanceladas([]));
      dispatchFilters(setFilterByTypeCanceladas([]));
      dispatchFilters(setFilterByRegionCanceladas([]));
    }
    if (
      locationFilters.pathname === ROUTES.activeAlerts.path &&
      path === ROUTES.activeAlerts.path &&
      (filterByTypeVigentes.length ||
        filterByLevelVigentes.length ||
        filterByRegionVigentes.length)
    ) {
      dispatchFilters(setFilterByLevelVigentes([]));
      dispatchFilters(setFilterByTypeVigentes([]));
      dispatchFilters(setFilterByRegionVigentes([]));
    }

    history.push(path);
    goToTopOfPage(path);
  };

  return (
    <IonMenu contentId="main-content" menuId="main-menu" swipeGesture={false}>
      <IonToolbar
        className={'clear-min-height'}
        style={{
          marginTop: 'calc(var(--space-4) + var(--ion-safe-area-top))',
          marginBottom: 'calc(var(--space-2) + var(--ion-safe-area-bottom))',
          // paddingTop: 'var(--ion-safe-area-top)',
          // paddingBottom: 'var(--ion-safe-area-bottom)',
        }}
      >
        {/* <IonTitle className="headline-medium-bold">Alertas SENAPRED</IonTitle> */}
        <img
          className="ion-margin"
          style={{
            marginBottom: '0',
          }}
          src={IncendiosLogo}
          alt="Incendios Forestales logo"
        ></img>
      </IonToolbar>
      <IonContent>
        <div
          style={{
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'space-between',
            height: '100%',
          }}
        >
          <div>
            <IonMenuToggle
              autoHide={false}
              menu="main-menu"
              className={`main-menu-item ${
                location === ROUTES.home.path && 'active'
              }`}
            >
              <IonItem
                // routerLink={ROUTES.home.path}
                onClick={() => changePage(ROUTES.home.path)}
                style={{
                  marginBottom: '15px',
                }}
                lines="none"
                className="ion-padding-horizontal main-menu-item"
              >
                <IonLabel
                  // style={{ marginLeft: 'var(--space-2)' }}
                  className="body-medium-medium"
                >
                  Inicio
                </IonLabel>
              </IonItem>
            </IonMenuToggle>

            <hr
              style={{
                backgroundColor: 'var(--itrend--blueGray-100)',
                width: '100%',
                height: '1px',
              }}
            />

            <p
              className="ion-margin main-menu-label body-small-bold ion-padding-horizontal"
              style={{
                marginLeft: '0',
              }}
            >
              Alertas
            </p>
            <IonMenuToggle
              autoHide={false}
              menu="main-menu"
              className={`main-menu-item ${
                location === ROUTES.map.path && 'active'
              }`}
            >
              <IonItem
                lines="none"
                // routerLink={`/tabs/vigentes`}
                onClick={() => changePage(ROUTES.map.path)}
                className="ion-padding-horizontal main-menu-item"
              >
                <IonLabel
                  // style={{ marginLeft: 'var(--space-2)' }}
                  className="body-medium-medium"
                >
                  Mapa de Alertas
                </IonLabel>
              </IonItem>
            </IonMenuToggle>
            <IonMenuToggle
              autoHide={false}
              menu="main-menu"
              className={`main-menu-item ${
                location === ROUTES.activeAlerts.path && 'active'
              }`}
            >
              <IonItem
                lines="none"
                // routerLink={`/tabs/vigentes`}
                onClick={() => changePage(ROUTES.activeAlerts.path)}
                className="ion-padding-horizontal main-menu-item"
              >
                <IonLabel
                  // style={{ marginLeft: 'var(--space-2)' }}
                  className="body-medium-medium"
                >
                  Vigentes
                </IonLabel>
              </IonItem>
            </IonMenuToggle>
            <IonMenuToggle
              autoHide={false}
              menu="main-menu"
              className={`main-menu-item ${
                location === ROUTES.subscriptions.path && 'active'
              }`}
            >
              <IonItem
                lines="none"
                onClick={() => changePage(ROUTES.subscriptions.path)}
                className="ion-padding-horizontal main-menu-item"
              >
                <IonLabel
                  // style={{ marginLeft: 'var(--space-2)' }}
                  className="body-medium-medium"
                >
                  {ROUTES.subscriptions.menuItemName}
                </IonLabel>
              </IonItem>
            </IonMenuToggle>
            <IonMenuToggle
              autoHide={false}
              menu="main-menu"
              className={`main-menu-item ${
                location === ROUTES.cancelledAlerts.path && 'active'
              }`}
            >
              <IonItem
                lines="none"
                onClick={() => changePage(ROUTES.cancelledAlerts.path)}
                className="ion-padding-horizontal main-menu-item"
              >
                <IonLabel
                  // style={{ marginLeft: 'var(--space-2)' }}
                  className="body-medium-medium"
                >
                  Canceladas
                </IonLabel>
              </IonItem>
            </IonMenuToggle>
            <hr
              style={{
                backgroundColor: 'var(--itrend--blueGray-100)',
                width: '100%',
                height: '1px',
              }}
            />

            <IonMenuToggle
              autoHide={false}
              menu="main-menu"
              className={`main-menu-item ${
                location === ROUTES.settings.path && 'active'
              }`}
            >
              <IonItem
                lines="none"
                routerLink={ROUTES.settings.path}
                className="ion-padding-horizontal main-menu-item"
              >
                <IonLabel
                  // style={{ marginLeft: 'var(--space-2)' }}
                  className="body-medium-medium"
                >
                  Configuración de suscripciones
                </IonLabel>
              </IonItem>
            </IonMenuToggle>

            <IonMenuToggle
              autoHide={false}
              menu="main-menu"
              className={`main-menu-item ${
                location === ROUTES.about.path && 'active'
              }`}
            >
              <IonItem
                lines="none"
                routerLink={ROUTES.about.path}
                className="ion-padding-horizontal main-menu-item"
              >
                <IonLabel
                  // style={{ marginLeft: 'var(--space-2)' }}
                  className="body-medium-medium"
                >
                  Acerca de
                </IonLabel>
              </IonItem>
            </IonMenuToggle>

            <IonMenuToggle
              autoHide={false}
              menu="main-menu"
              className={`main-menu-item ${
                location === ROUTES.politica.path && 'active'
              }`}
            >
              <IonItem
                lines="none"
                routerLink={ROUTES.politica.path}
                className="ion-padding-horizontal main-menu-item"
              >
                <IonLabel
                  // style={{ marginLeft: 'var(--space-2)' }}
                  className="body-medium-medium"
                >
                  Política de privacidad
                </IonLabel>
              </IonItem>
            </IonMenuToggle>
          </div>

          <div
            style={{
              display: 'flex',
              flexDirection: 'column',
              alignItems: 'flex-start',
              justifyContent: 'center',
              paddingBottom: 'var(--space-4)',
            }}
          >
            <hr
              style={{
                backgroundColor: 'var(--itrend--blueGray-100)',
                width: '100%',
                height: '1px',
              }}
            />
            <div
              className="ion-padding-horizontal"
              style={{
                display: 'flex',
                // flexDirection: 'column',
                alignItems: 'flex-start',
                width: '100%',
                padding: '0 var(--space-4)',
                // paddingBottom: '0',
                paddingTop: 'var(--space-2)',
              }}
            >
              <IonAvatar
                style={{
                  width: '32px',
                  height: '32px',
                  marginTop: 'var(--space-1)',
                }}
              >
                <img
                  alt="Silhouette of a person's head"
                  src="/assets/account-circle.svg"
                />
              </IonAvatar>

              <div
                style={{
                  display: 'flex',
                  flexDirection: 'column',
                  alignItems: 'flex-start',
                  justifyContent: 'center',
                  marginLeft: 'var(--space-2)',
                }}
              >
                <IonLabel
                  style={{
                    fontWeight: 'var(--font-weight-regular)',
                    fontSize: 'var(--body-small)',
                    color: 'var(--itrend--gray-900)',
                  }}
                >
                  <span
                    style={{
                      fontWeight: 'var(--font-weight-bold)',
                    }}
                  >
                    {username}
                  </span>
                </IonLabel>
                <IonLabel
                  style={{
                    fontWeight: 'var(--font-weight-regular)',
                    fontSize: 'var(--body-small)',
                    color: 'var(--itrend--gray-900)',
                  }}
                >
                  {name} {family_name}
                </IonLabel>
                <IonButton
                  fill="clear"
                  onClick={() => performLogout()}
                  className="logout-button"
                >
                  <a
                    // href={logoutUrl}
                    style={{
                      textDecoration: 'none',
                      color: 'var(--itrend--secondary-500)',
                      fontWeight: 'var(--font-weight-regular)',
                      fontSize: 'var(--body-small)',
                    }}
                  >
                    Cerrar sesión
                  </a>
                </IonButton>
              </div>
            </div>
            <hr
              style={{
                backgroundColor: 'var(--itrend--blueGray-100)',
                width: '100%',
                height: '1px',
              }}
            />
            <div
              style={{
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'start',
                width: '100%',
                padding: '0 var(--space-4)',
              }}
            >
              <div
                style={{
                  display: 'flex',
                  justifyContent: 'space-between',
                  alignItems: 'center',
                  width: '100%',
                }}
              >
                <img
                  src="/assets/itrend-logo-blue.svg"
                  alt="Itrend logo"
                  // width="130"
                />
                <img
                  src={SenapredLogo}
                  alt="Senapred logo"
                  // width="80"
                />
              </div>

              <p
                style={{
                  // textAlign: 'center',
                  fontStyle: 'normal',
                  fontWeight: 'var(--font-weight-normal)',
                  fontSize: 'var(--caption)',
                  marginBottom: '0',

                  // color: 'var(--itrend--brand-DarkSlateBlue)',
                }}
              >
                © 2023 Instituto para la Resiliencia ante Desastres - Itrend
              </p>
              <p
                style={{
                  textAlign: 'center',
                  fontStyle: 'normal',
                  fontSize: 'var(--caption)',
                  // color: 'var(--itrend--brand-DarkSlateBlue)',
                  marginBottom: '0',
                  marginTop: 'var(--space-2)',
                }}
              >{`Versión: ${appVersion || '1.0.0'} © Itrend`}</p>
            </div>
          </div>
        </div>
      </IonContent>
    </IonMenu>
  );
};

export default Menu;
