import React, { useRef, useState } from 'react';
import { useDispatch } from 'react-redux';
import { Redirect, Route } from 'react-router-dom';
import { Dispatch } from 'redux';

/* eslint-disable @typescript-eslint/no-unused-vars */
import {
  IonIcon,
  IonLabel,
  IonRouterOutlet,
  IonTabBar,
  IonTabButton,
  IonTabs,
  useIonRouter,
} from '@ionic/react';

import { ErrorComponent, Menu } from '../components';
import FilterMenu from '../components/FilterMenu';
import MapRegionalContainer from '../components/map/MapRegionalContainer';
import { ROUTES, errorsRoutes } from '../constants';
import {
  alert_type_amarilla_str,
  alert_type_preventiva_str,
  alert_type_roja_str,
} from '../features/notifications/notifications';
import {
  setFilterByLevelVigentes,
  setFilterByRegionVigentes,
  setFilterByTypeVigentes,
} from '../features/settings/settingsActions';
import { useAppSelector } from '../hooks';
import { RootState } from '../store';

import activeActIcon from '../theme/icons/tabs-active-act.svg';
import activeIcon from '../theme/icons/tabs-active.svg';
import favActIcon from '../theme/icons/tabs-bookmark-act.svg';
import favIcon from '../theme/icons/tabs-bookmark.svg';
import homeActIcon from '../theme/icons/tabs-home-act.svg';
import homeIcon from '../theme/icons/tabs-home.svg';
import mapActIcon from '../theme/icons/tabs-map-act.svg';
import mapIcon from '../theme/icons/tabs-map.svg';

import About from './About';
import Politica from './Politica';
import AlertDetails from './AlertDetails';
import Canceladas from './Canceladas';
import EventDetails from './EventDetails';
import HealthCheck from './HealthCheck';
import HealthCheckNotifications from './HealthCheckNotifications';
import Home from './Home';
import Map from './Map';
import Lifecycle from './notifications/Lifecycle';
import Regions from './notifications/Regions';
import Types from './notifications/Types';
import Guide from './notifications/Guide';
import NotificationSettings from './NotificationSettings';
import Suscripciones from './Suscripciones';
import Vigentes from './Vigentes';
import { GlobalLoader } from '../components';

export const goToTopOfPage = (path: string) => {
  const el = document.getElementById(
    `scroll-to-top-${path.split('/')[2]}`
  ) as HTMLElement | null;

  if (el) {
    el.scrollIntoView({
      // behavior: 'smooth',
      block: 'start',
      inline: 'nearest',
    });
  }
};

const AppTabs: React.FC = () => {
  const location = window.location;
  const router = useIonRouter();
  const refMenu = useRef<HTMLIonMenuElement>(null);
  const [isFilterMenuOpen, setIsFilterMenuOpen] = useState(false);

  const dispatch: Dispatch<any> = useDispatch();

  const {
    filterByTypeVigentes,
    filterByLevelVigentes,
    filterByRegionVigentes,
  } = useAppSelector((state: RootState) => state.settings);

  const changeTab = (path: string) => {
    if (
      location.pathname === ROUTES.activeAlerts.path &&
      path === ROUTES.activeAlerts.path &&
      (filterByTypeVigentes.length ||
        filterByLevelVigentes.length ||
        filterByRegionVigentes.length)
    ) {
      dispatch(setFilterByLevelVigentes([]));
      dispatch(setFilterByTypeVigentes([]));
      dispatch(setFilterByRegionVigentes([]));
    }

    // router.push(path, 'root', 'replace');
    goToTopOfPage(path);
  };

  const {
    isNotificationsInitializingNative,
    isNotificationsInitializingWebpush,
  } = useAppSelector((state: RootState) => state.notifications);

  return (
    // <IonContent scrollEvents={true}>
    <>
      <Menu tabPrefix="" location={location.pathname} />
      {isFilterMenuOpen && (
        <FilterMenu
          refMenu={refMenu}
          setIsFilterMenuOpen={setIsFilterMenuOpen}
        />
      )}
      <GlobalLoader />
      <IonTabs>
        <IonRouterOutlet id="main-content">
          {/* <Switch> */}
          <Route exact path={ROUTES.home.path} render={() => <Home />} />
          <Route
            exact
            path={`${ROUTES.home.path}${ROUTES.alertDetails.path}`}
            render={() => <AlertDetails />}
          />
          <Route
            exact
            path={`${ROUTES.home.path}${ROUTES.alertDetails.path}${ROUTES.eventDetails.path}`}
            render={() => <EventDetails />}
          />

          <Route exact path={ROUTES.map.path} render={() => <Map />} />
          <Route
            exact
            path={`${ROUTES.map.path}${ROUTES.region.path}`}
            render={() => <MapRegionalContainer />}
          />
          <Route
            exact
            path={`${ROUTES.map.path}${ROUTES.region.path}${ROUTES.alertDetails.path}`}
            render={() => <AlertDetails />}
          />
          <Route
            exact
            path={`${ROUTES.map.path}${ROUTES.region.path}${ROUTES.alertDetails.path}${ROUTES.eventDetails.path}`}
            render={() => <EventDetails />}
          />

          <Route
            exact
            path={ROUTES.activeAlerts.path}
            render={() => (
              <Vigentes
                refMenu={refMenu}
                setIsFilterMenuOpen={setIsFilterMenuOpen}
              />
            )}
          />
          <Route
            exact
            path={`${ROUTES.activeAlerts.path}${ROUTES.alertDetails.path}`}
            render={() => <AlertDetails />}
          />
          <Route
            exact
            path={`${ROUTES.activeAlerts.path}${ROUTES.alertDetails.path}${ROUTES.eventDetails.path}`}
            render={() => <EventDetails />}
          />

          <Route
            exact
            path={ROUTES.subscriptions.path}
            render={() => <Suscripciones />}
          />
          <Route
            exact
            path={`${ROUTES.subscriptions.path}${ROUTES.alertDetails.path}`}
            render={() => <AlertDetails />}
          />
          <Route
            exact
            path={`${ROUTES.subscriptions.path}${ROUTES.alertDetails.path}${ROUTES.eventDetails.path}`}
            render={() => <EventDetails />}
          />

          <Route
            exact
            path={ROUTES.cancelledAlerts.path}
            render={() => (
              <Canceladas
                refMenu={refMenu}
                setIsFilterMenuOpen={setIsFilterMenuOpen}
              />
            )}
          />
          <Route
            exact
            path={`${ROUTES.cancelledAlerts.path}${ROUTES.alertDetails.path}`}
            render={() => <AlertDetails />}
          />
          <Route
            exact
            path={`${ROUTES.cancelledAlerts.path}${ROUTES.alertDetails.path}${ROUTES.eventDetails.path}`}
            render={() => <EventDetails />}
          />

          {/* share urls */}
          <Route
            exact
            path={`${ROUTES.shared.path}${ROUTES.alertDetails.path}`}
            render={() => <AlertDetails />}
          />
          <Route
            exact
            path={`${ROUTES.shared.path}${ROUTES.alertDetails.path}${ROUTES.eventDetails.path}`}
            render={() => <EventDetails />}
          />

          {/* notifications settings url */}
          <Route
            exact
            path={ROUTES.settings.path}
            render={() => <NotificationSettings refMenu={refMenu} />}
          />
          {/* <Route
            exact
            path={'/tabs/settings/regions'}
            render={() => <Regions />}
          /> */}
          <Route
            exact
            path={`${ROUTES.settings.path}/types`}
            render={() => <Types />}
          />
          <Route
            exact
            path={`${ROUTES.settings.path}/types/roja`}
            render={() => <Regions alertType={alert_type_roja_str} />}
          />
          <Route
            exact
            path={`${ROUTES.settings.path}/types/amarilla`}
            render={() => <Regions alertType={alert_type_amarilla_str} />}
          />
          <Route
            exact
            path={`${ROUTES.settings.path}/types/temprana`}
            render={() => <Regions alertType={alert_type_preventiva_str} />}
          />
          <Route
            exact
            path={`${ROUTES.settings.path}/lifecycle`}
            render={() => <Lifecycle />}
          />
          <Route
            exact
            path={`${ROUTES.settings.path}/guide`}
            render={() => <Guide />}
          />

          <Route exact path={ROUTES.about.path} render={() => <About />} />
          <Route
            exact
            path={ROUTES.politica.path}
            render={() => <Politica />}
          />

          <Route
            exact
            path={ROUTES.healthCheck.path}
            render={() => <HealthCheck />}
          />

          <Route
            exact
            path={ROUTES.healthCheckNotifications.path}
            render={() => <HealthCheckNotifications />}
          />

          {Object.keys(errorsRoutes).map((e: any) => {
            return (
              <Route
                key={e}
                exact
                path={'/tabs/' + e}
                render={() => (
                  <ErrorComponent
                    code={e}
                    header={errorsRoutes[e as keyof typeof errorsRoutes].header}
                    message={
                      errorsRoutes[e as keyof typeof errorsRoutes].message
                    }
                  />
                )}
              />
            );
          })}

          <Route
            exact
            path={ROUTES.error.path}
            render={() => (
              <ErrorComponent
                code={'error'}
                header={ROUTES.error.header}
                message={ROUTES.error.message}
              />
            )}
          />

          <Route render={() => <Redirect to={ROUTES['404'].path} />} />

          {/* </Switch> */}
        </IonRouterOutlet>
        <IonTabBar
          slot="bottom"
          // style={{
          //   height: '62px',
          // }}
          className={
            isNotificationsInitializingNative ||
            isNotificationsInitializingWebpush
              ? 'ion-tab-bar-loading'
              : ''
          }
        >
          <IonTabButton
            className={
              isNotificationsInitializingNative ||
              isNotificationsInitializingWebpush
                ? 'no-padding-end ion-tab-button-loading'
                : 'no-padding-end'
            }
            tab="home"
            href={ROUTES.home.path}
            selected={location.pathname.includes('home') ? true : false}
            onClick={() => {
              changeTab(ROUTES.home.path);
            }}
          >
            <IonIcon
              src={homeIcon}
              className="tab-unselected-icon"
              aria-label="Ir a página de inicio"
            />
            <IonIcon
              src={homeActIcon}
              className="tab-selected-icon"
              aria-label="Página de inicio"
            />
            <IonLabel
              style={{
                fontSize: 'var(--caption)',
                fontWeight: 'var(--font-weight-regular)',
                marginTop: 'var(--space-1)',
              }}
            >
              Inicio
            </IonLabel>
          </IonTabButton>

          <IonTabButton
            className={
              isNotificationsInitializingNative ||
              isNotificationsInitializingWebpush
                ? 'no-padding-end ion-tab-button-loading'
                : 'no-padding-end'
            }
            tab="mapa"
            href={ROUTES.map.path}
            selected={location.pathname.includes('mapa') ? true : false}
            onClick={() => {
              changeTab(ROUTES.map.path);
            }}
          >
            <IonIcon
              src={mapIcon}
              className="tab-unselected-icon"
              aria-label="Ir a mapa"
            />
            <IonIcon
              src={mapActIcon}
              className="tab-selected-icon"
              aria-label="Mapa"
            />
            <IonLabel
              style={{
                fontSize: 'var(--caption)',
                fontWeight: 'var(--font-weight-regular)',
                marginTop: 'var(--space-1)',
              }}
            >
              {ROUTES.map.tabItemName}
            </IonLabel>
          </IonTabButton>

          <IonTabButton
            className={
              isNotificationsInitializingNative ||
              isNotificationsInitializingWebpush
                ? 'no-padding-end ion-tab-button-loading'
                : 'no-padding-end'
            }
            tab="vigentes"
            href={ROUTES.activeAlerts.path}
            selected={location.pathname.includes('vigentes') ? true : false}
            onClick={() => {
              changeTab(ROUTES.activeAlerts.path);
            }}
          >
            <IonIcon
              src={activeIcon}
              className="tab-unselected-icon"
              size="24px"
              style={{
                height: '24px',
              }}
              aria-label="Ir a alertas vigentes"
            />
            <IonIcon
              src={activeActIcon}
              className="tab-selected-icon"
              aria-label="Alertas vigentes"
            />
            <IonLabel
              style={{
                fontSize: 'var(--caption)',
                fontWeight: 'var(--font-weight-regular)',
                marginTop: 'var(--space-1)',
              }}
            >
              Vigentes
            </IonLabel>
          </IonTabButton>

          <IonTabButton
            className={
              isNotificationsInitializingNative ||
              isNotificationsInitializingWebpush
                ? 'no-padding-end ion-tab-button-loading'
                : 'no-padding-end'
            }
            tab="subscriptions"
            href={ROUTES.subscriptions.path}
            selected={
              location.pathname.includes('suscripciones') ? true : false
            }
            onClick={() => {
              changeTab(ROUTES.subscriptions.path);
            }}
          >
            <IonIcon
              src={favIcon}
              className="tab-unselected-icon"
              aria-label="Ir a suscripciones"
            />
            <IonIcon
              src={favActIcon}
              className="tab-selected-icon"
              aria-label="Suscripciones"
            />

            <IonLabel
              style={{
                fontSize: 'var(--caption)',
                fontWeight: 'var(--font-weight-regular)',
                marginTop: 'var(--space-1)',
              }}
            >
              {ROUTES.subscriptions.tabItemName}
            </IonLabel>
          </IonTabButton>
        </IonTabBar>
      </IonTabs>
      {/* </IonContent> */}
    </>
  );
};

export default AppTabs;
