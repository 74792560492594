import { initializeApp } from 'firebase/app';
import React, { useEffect, useRef } from 'react';

import { Capacitor, PermissionState } from '@capacitor/core';

import {
  alert_type_amarilla_str,
  alert_type_preventiva_str,
  alert_type_roja_str,
  AlertType,
  cutCodesToRegion,
  name2alertType,
  RegionCode,
  StaticTopicType,
} from './features/notifications/notifications';
import {
  appendTestNotificationData,
  setCurrentNotificationPermissions,
  setFirebaseInitialized,
  updateFirebaseOptionsAndARN,
  updateNotificationsRegionTypeSettings,
  updateSnsEndpoint,
  updateStaticTopics,
  isNotificationsInitializingWebpush,
} from './features/notifications/notificationsActions';
import { toggleAlertsListUpdate } from './features/favorites/favoritesActions';
import {
  getPermissions,
  getToken,
  getTopicFromSubscription,
} from './firebaseUtils';
import { useAppDispatch, useAppSelector } from './hooks';
import { RootState } from './store';
import { CDAlertasApi } from './services/CDAlertasApi';
import { useAuthenticator } from '@aws-amplify/ui-react';
import { addCardToFavorites } from './features/favorites/favoritesActions';
import { addCardToFavoritesWithoutSubscribingBulk } from './features/favorites/favoritesActions';

const NotificationsInitializerWebpush: React.FC = () => {
  const dispatch = useAppDispatch();

  const didRegisterHandlers = useRef(false);

  const [getNotificationPlatformData] =
    CDAlertasApi.useLazyGetNotificationPlatformsQuery();

  const [getCurrentSubscriptionsData] =
    CDAlertasApi.useLazySnsGetSubscriptionsQuery();

  // Obtain logged user info
  const { user } = useAuthenticator((context) => [context.user]);

  const user_id = user?.attributes?.sub;
  const user_name = user?.username;

  const { currentFavorites, historyFavorites } = useAppSelector(
    (state: RootState) => state.favorites
  );

  /* ******************************************************************************/
  /* INITIALIZE FIREBASE NOTIFICATIONS FOR PWA PLATFORM */
  useEffect(() => {
    const doEffect = async () => {
      dispatch(isNotificationsInitializingWebpush(true));

      const { payload: staticTopicsPayload } = await dispatch(
        updateStaticTopics()
      );

      if (Capacitor.getPlatform() === 'web') {
        /* *************************** */
        /* 
            We must initialize firebase this way only for the web platform.
            In native platforms, the initialization is done in the plugin library.
          */
        const { data: platformData } = await getNotificationPlatformData({});

        const firebaseSettings = {
          apiKey: platformData['apiKey'],
          appId: platformData['appId'],
          authDomain: platformData['authDomain'],
          messagingSenderId: platformData['messagingSenderId'],
          projectId: platformData['projectId'],
          storageBucket: platformData['storageBucket'],
        };

        initializeApp(firebaseSettings);
        await dispatch(updateFirebaseOptionsAndARN(platformData));
        await dispatch(setFirebaseInitialized());

        // * We want to continuously unregister and register the service worker
        // * of the user in case we have changed it and needs refreshing.
        let registration = null;
        if ('serviceWorker' in navigator) {
          // Obtain current or new service worker registration.
          registration = await navigator.serviceWorker.getRegistration();
          if (registration) {
            await registration.update();
          } else {
            registration = await navigator.serviceWorker.register(
              'firebase-messaging-sw.js'
            );
          }

          navigator.serviceWorker.onmessage = (event) => {
            const eventType = event?.data?.type;
            if (eventType === 'TEST_NOTIFICATION') {
              const testId = event?.data?.testId;
              const sourceTopicARN = event?.data?.sourceTopicARN;
              if (testId && sourceTopicARN) {
                dispatch(
                  appendTestNotificationData({
                    testId,
                    sourceTopicARN,
                  })
                );
              }
            }
          };
        } else {
          console.log('serviceWorker not available in navigator.');
        }

        // ! We require permissions to be granted before requesting token.
        const currentPermissions: PermissionState = await getPermissions();

        // * Update global setting of current permissions
        await dispatch(
          setCurrentNotificationPermissions([currentPermissions, undefined])
        );

        if (currentPermissions === 'granted') {
          // Obtain and dispatch token for current service worker registration.
          const token = await getToken(platformData['vapid'], registration);

          // **********************************************************************
          // Update endpoint accordingly to token obtained.
          const { payload } = await dispatch(
            updateSnsEndpoint({
              new_fcm_token: token,
              user_id: user_id,
              user_name: user_name,
            })
          );

          // * payload has type: string | { token?: string, endpointARN?: string }
          const endpointARN =
            typeof payload === 'object' && payload?.endpointARN;

          const staticTopics =
            typeof staticTopicsPayload === 'object' &&
            staticTopicsPayload.staticTopics;

          if (
            endpointARN !== false &&
            endpointARN !== undefined &&
            staticTopics !== false
          ) {
            const { data: currentSubscriptionsData } =
              await getCurrentSubscriptionsData({
                endpointARN,
              });

            const currentSubscriptionsTopics = (
              currentSubscriptionsData['Subscriptions'] || []
            ).map(getTopicFromSubscription);

            const staticTopicsByARN: Record<string, StaticTopicType> =
              Object.fromEntries(
                staticTopics.map((e: StaticTopicType) => [e['arn'], e])
              );

            const currentSubscribedStaticTopics: StaticTopicType[] =
              currentSubscriptionsTopics
                .filter((t: string) => t in staticTopicsByARN)
                .map((t: string) => staticTopicsByARN[t]);

            const currentSubscribedNotStaticTopics: any[] =
              currentSubscriptionsTopics.filter(
                (t: string) => !(t in staticTopicsByARN)
              );

            const currentSubscribedAlertsIds =
              currentSubscribedNotStaticTopics.map(
                (el: any) => el.split('_').slice(-1)[0]
              );

            // checking if the are new alerts to autosubscribe to
            const alertsIdsList = [];
            for (const alertId of currentSubscribedAlertsIds) {
              if (
                !currentFavorites.includes(alertId) &&
                !historyFavorites.includes(alertId)
              ) {
                alertsIdsList.push(alertId);
              }
            }

            if (alertsIdsList.length > 0) {
              await dispatch(
                addCardToFavoritesWithoutSubscribingBulk(alertsIdsList)
              );
              await dispatch(toggleAlertsListUpdate(true));
            }
            // end of checking

            const regionsAlertaRoja = [];
            const regionsAlertaAmarilla = [];
            const regionsAlertaPreventiva = [];
            for (const topic of currentSubscribedStaticTopics) {
              const alertType: AlertType = name2alertType[topic['alertStatus']];
              const regionCode: RegionCode =
                cutCodesToRegion[topic['regionCut']];
              if (alertType === alert_type_roja_str) {
                regionsAlertaRoja.push([regionCode, true]);
              } else if (alertType === alert_type_amarilla_str) {
                regionsAlertaAmarilla.push([regionCode, true]);
              } else if (alertType === alert_type_preventiva_str) {
                regionsAlertaPreventiva.push([regionCode, true]);
              }
            }

            const settingsUpdate: Record<
              AlertType,
              Record<RegionCode, boolean>
            > = {
              alerta_roja: Object.fromEntries(regionsAlertaRoja),
              alerta_amarilla: Object.fromEntries(regionsAlertaAmarilla),
              alerta_temprana_preventiva: Object.fromEntries(
                regionsAlertaPreventiva
              ),
            };
            await dispatch(
              updateNotificationsRegionTypeSettings(settingsUpdate)
            );
          }
        }
      }

      dispatch(isNotificationsInitializingWebpush(false));
    };

    if (!didRegisterHandlers.current) {
      doEffect();
      didRegisterHandlers.current = true;
    }
  }, []);

  return <></>;
};

export default NotificationsInitializerWebpush;
