import React, { useEffect, useRef } from 'react';
import { useHistory } from 'react-router-dom';

import { useAuthenticator } from '@aws-amplify/ui-react';
import { FirebaseMessaging } from '@capacitor-firebase/messaging';
import { Capacitor, PermissionState } from '@capacitor/core';
import {
  ActionPerformed,
  Channel,
  LocalNotifications,
  LocalNotificationSchema,
} from '@capacitor/local-notifications';

import {
  alert_type_amarilla_str,
  alert_type_preventiva_str,
  alert_type_roja_str,
  AlertType,
  cutCodesToRegion,
  name2alertType,
  RegionCode,
  StaticTopicType,
} from './features/notifications/notifications';
import {
  appendTestNotificationData,
  setCurrentNotificationPermissions,
  setFirebaseInitialized,
  updateFirebaseOptionsAndARN,
  updateNotificationsRegionTypeSettings,
  updateSnsEndpoint,
  updateStaticTopics,
  isNotificationsInitializingNative,
} from './features/notifications/notificationsActions';
import {
  getPermissions,
  getToken,
  getTopicFromSubscription,
  requestPermissions,
} from './firebaseUtils';
import { useAppDispatch, useAppSelector } from './hooks';
import { RootState } from './store';
import { CDAlertasApi } from './services/CDAlertasApi';
import { AppDispatch } from './store';
import { addCardToFavoritesWithoutSubscribingBulk } from './features/favorites/favoritesActions';
import { toggleAlertsListUpdate } from './features/favorites/favoritesActions';
import { sendActivityToRum } from './App';

const scheduleLocalNotificationNow = async ({
  title,
  body,
  id,
  data = {},
}: {
  title: string;
  body: string;
  id: number;
  data?: Record<string, any>;
}) => {
  const notification: LocalNotificationSchema = {
    title: title,
    body: body,
    id: id,
    extra: data,
    // We need some seconds for padding, otherwise the notification doesn't appear.
    schedule: { at: new Date(Date.now() + 3000) },
    channelId: 'itrendcl-condor-channel',
  };

  return LocalNotifications.schedule({ notifications: [notification] });
};

const addListeners = async (dispatch: AppDispatch, history: any) => {
  // * Initialize Android notification channel for high-priority notifications.
  // When in foreground, these notifications appear as alerts on top.
  // This is also known as "heads-up" notifications. There is no similar concept on iOS.
  if (Capacitor.getPlatform() === 'android') {
    const channel: Channel = {
      id: 'itrendcl-condor-channel',
      name: 'itrendcl-condor',
      importance: 4,
      visibility: 1,
      lights: true,
    };
    console.log('Recreating itrendcl-condor-channel channel');
    await LocalNotifications.deleteChannel({ id: 'itrendcl-condor-channel' });
    await LocalNotifications.createChannel(channel);

    const channels = await LocalNotifications.listChannels();
    console.log('Got Android notification channels: ', channels);
  }

  // ! Capacitor PushNotifications handlers do not fire.
  // For 'pushNotificationReceived' and 'pushNotificationActionPerformed' events,
  // the handlers are not triggering because they are captured first by
  // @capacitor-firebase/messaging plugin. We use the latter.
  await FirebaseMessaging.addListener('notificationReceived', (event) => {
    console.log('notificationReceived', { event });

    const payload: Record<string, any> = event.notification?.data || {};
    const alertId = payload['alertId'] || '0';
    const isTestAlert = payload['isTestAlert'] || false;
    const sourceTopicARN = payload['sourceTopicARN'] || '';

    if (Capacitor.getPlatform() === 'android') {
      scheduleLocalNotificationNow({
        title: event.notification.title || 'Notificación',
        body: event.notification.body || '',
        id: parseInt(alertId),
        data: payload,
      }).then((r) => {
        console.log('Local notification scheduled with result:', r),
          (error: any) => {
            console.error('Error scheduling local notification:', error);
          };
      });
    }

    // * Handle test alerts.
    if (isTestAlert && alertId && sourceTopicARN) {
      dispatch(
        appendTestNotificationData({
          testId: alertId,
          sourceTopicARN,
        })
      );
    }
  });

  // * Notification action performed for background (push) notification.
  await FirebaseMessaging.addListener(
    'notificationActionPerformed',
    (event) => {
      console.log('Notification action performed on event:', event);

      const payload: Record<string, any> = event.notification?.data || {};
      const urlSuffix = payload['url_suffix'];

      // const isIOS = Capacitor.getPlatform() === 'ios';

      if (urlSuffix) {
        history.push({
          pathname: urlSuffix,
          state: { isFromNotification: true },
        });
        sendActivityToRum('notification', 'click', 'background');
      }
    }
  );

  // * Notification action performed for foreground (local) notification.
  await LocalNotifications.addListener(
    'localNotificationActionPerformed',
    (notificationAction: ActionPerformed) => {
      console.log('Local notifications action performed:', notificationAction);

      const payload: Record<string, any> =
        notificationAction.notification?.extra || {};
      const urlSuffix = payload['url_suffix'];

      // const isIOS = Capacitor.getPlatform() === 'ios';

      if (urlSuffix) {
        history.push({
          pathname: urlSuffix,
          state: { isFromNotification: true },
        });
        sendActivityToRum('notification', 'click', 'foreground');
      }
    }
  );
};

const getPermissionsNative = async (): Promise<
  [PermissionState, PermissionState]
> => {
  const pushResultReceive = await getPermissions();
  const localResult = await LocalNotifications.checkPermissions();
  return [pushResultReceive, localResult.display];
};

const NotificationsInitializerNative: React.FC = () => {
  const { user } = useAuthenticator((context) => [context.user]);

  const user_id = user?.attributes?.sub;
  const user_name = user?.username;

  const dispatch = useAppDispatch();

  const [getCurrentSubscriptionsData] =
    CDAlertasApi.useLazySnsGetSubscriptionsQuery();

  const [getNotificationPlatformData] =
    CDAlertasApi.useLazyGetNotificationPlatformsQuery();

  const didRegisterHandlers = useRef(false);

  const history = useHistory();

  const { currentFavorites, historyFavorites } = useAppSelector(
    (state: RootState) => state.favorites
  );

  useEffect(() => {
    const doEffect = async () => {
      dispatch(isNotificationsInitializingNative(true));

      const { payload: staticTopicsPayload } = await dispatch(
        updateStaticTopics()
      );

      // * Firebase platform initialization ought to be performed
      // * by Capacitor-Firebase library, using google-services.json
      // * However, we need the platformApplicationARN data.
      const { data: platformData } = await getNotificationPlatformData({});

      await dispatch(updateFirebaseOptionsAndARN(platformData));
      await dispatch(setFirebaseInitialized());

      if (Capacitor.isNativePlatform()) {
        // * No service worker registration required.

        // * We must register the handlers before proceeding,
        // * to intercept the register callback.
        await addListeners(dispatch, history);

        // ! We require permissions to be granted before requesting token.
        const currentPermissions: [PermissionState, PermissionState?] =
          await getPermissionsNative();

        // * Update global setting of current permissions
        await dispatch(setCurrentNotificationPermissions(currentPermissions));

        if (
          currentPermissions[0] === 'granted' &&
          currentPermissions[1] &&
          currentPermissions[1] === 'granted'
        ) {
          // * // Obtain and dispatch token for notifications registration.
          const token = await getToken(platformData['vapid'], null);

          // * Update endpoint accordingly to token obtained.
          const { payload } = await dispatch(
            updateSnsEndpoint({
              new_fcm_token: token,
              user_id: user_id,
              user_name: user_name,
            })
          );

          // * payload has type: string | { token?: string, endpointARN?: string }
          const endpointARN =
            typeof payload === 'object' && payload?.endpointARN;

          const staticTopics =
            typeof staticTopicsPayload === 'object' &&
            staticTopicsPayload.staticTopics;

          if (
            endpointARN !== false &&
            endpointARN !== undefined &&
            staticTopics !== false
          ) {
            const { data: currentSubscriptionsData } =
              await getCurrentSubscriptionsData({
                endpointARN,
              });

            const currentSubscriptionsTopics = (
              currentSubscriptionsData['Subscriptions'] || []
            ).map(getTopicFromSubscription);

            const staticTopicsByARN: Record<string, StaticTopicType> =
              Object.fromEntries(
                staticTopics.map((e: StaticTopicType) => [e['arn'], e])
              );

            const currentSubscribedStaticTopics: StaticTopicType[] =
              currentSubscriptionsTopics
                .filter((t: string) => t in staticTopicsByARN)
                .map((t: string) => staticTopicsByARN[t]);

            const currentSubscribedNotStaticTopics: any[] =
              currentSubscriptionsTopics.filter(
                (t: string) => !(t in staticTopicsByARN)
              );

            const currentSubscribedAlertsIds =
              currentSubscribedNotStaticTopics.map(
                (el: any) => el.split('_').slice(-1)[0]
              );

            // checking if the are new alerts to autosubscribe to
            const alertsIdsList = [];
            for (const alertId of currentSubscribedAlertsIds) {
              if (
                !currentFavorites.includes(alertId) &&
                !historyFavorites.includes(alertId)
              ) {
                alertsIdsList.push(alertId);
              }
            }

            if (alertsIdsList.length > 0) {
              await dispatch(
                addCardToFavoritesWithoutSubscribingBulk(alertsIdsList)
              );
              await dispatch(toggleAlertsListUpdate(true));
            }
            // end of checking

            const regionsAlertaRoja = [];
            const regionsAlertaAmarilla = [];
            const regionsAlertaPreventiva = [];
            for (const topic of currentSubscribedStaticTopics) {
              const alertType: AlertType = name2alertType[topic['alertStatus']];
              const regionCode: RegionCode =
                cutCodesToRegion[topic['regionCut']];
              if (alertType === alert_type_roja_str) {
                regionsAlertaRoja.push([regionCode, true]);
              } else if (alertType === alert_type_amarilla_str) {
                regionsAlertaAmarilla.push([regionCode, true]);
              } else if (alertType === alert_type_preventiva_str) {
                regionsAlertaPreventiva.push([regionCode, true]);
              }
            }

            const settingsUpdate: Record<
              AlertType,
              Record<RegionCode, boolean>
            > = {
              alerta_roja: Object.fromEntries(regionsAlertaRoja),
              alerta_amarilla: Object.fromEntries(regionsAlertaAmarilla),
              alerta_temprana_preventiva: Object.fromEntries(
                regionsAlertaPreventiva
              ),
            };
            await dispatch(
              updateNotificationsRegionTypeSettings(settingsUpdate)
            );
          }
        }
      }

      dispatch(isNotificationsInitializingNative(false));
    };

    if (!didRegisterHandlers.current) {
      doEffect();
      didRegisterHandlers.current = true;
    }
  }, []);

  return <></>;
};

export default NotificationsInitializerNative;
