import { CardProps } from '../components/AlertCard';
import SenapredLogo from '../theme/senapred_logo.svg';

export const sortByValues = [
  'Actualizacion mas reciente',
  'Mayor tiempo en vigencia',
  'Fecha de inicio',
];
// {'Actualizacion mas reciente': '-updatedAt'},
// {'Mayor tiempo en vigencia': 'creationTime'},
// {'Fecha de inicio': 'creationTime'}

export const alertTypes = {
  'Temprana Preventiva': 'green',
  Amarilla: '#a8932a',
  Roja: 'red',
};

export const regionPrefix = {
  '05': 'de ',
  '08': 'del ',
  '01': 'de ',
  '02': 'de ',
  '03': 'de ',
  '04': 'de ',
  '06': 'de ',
  '07': 'del ',
  '09': 'de ',
  '10': 'de ',
  '11': 'de ',
  '12': 'de ',
  '13': '',
  '14': 'de ',
  '15': 'de ',
  '16': 'de ',
};

export const zones: Record<
  string,
  { code: string; comunas: Record<string, string>[] }
> = {
  Norte: {
    code: 'mn',
    comunas: [
      { 'Arica y Parinacota': '15' },
      { Tarapacá: '01' },
      { Antofagasta: '02' },
      { Atacama: '03' },
    ],
  },
  Centro: {
    code: 'mc',
    comunas: [{ Coquimbo: '04' }, { Valparaíso: '05' }],
  },
  Metropolitana: {
    code: 'rm',
    comunas: [{ Metropolitana: '13' }],
  },
  'Centro Sur': {
    code: 'mcs',
    comunas: [
      { "O'Higgins": '06' },
      { Maule: '07' },
      { Ñuble: '16' },
      { Biobío: '08' },
    ],
  },
  Sur: {
    code: 'ms',
    comunas: [{ Araucanía: '09' }, { 'Los Ríos': '14' }, { 'Los Lagos': '10' }],
  },
  Austral: {
    code: 'ma',
    comunas: [{ Aysén: '11' }, { Magallanes: '12' }],
  },
};

export const regions = Object.values(zones)
  .map((zone: { comunas: unknown[] }) => zone.comunas)
  .reduce(function (flattenedArray, element) {
    return [...flattenedArray, ...element];
  }, []);

export const countryRings: any = [
  [-14.771235045795796, -62.15884147321298],
  [-57.39412235502263, -79.3001471543069],
];

export const regionsRings = {
  '01': [
    [-18.673280832086185, -67.9935871920594],
    [-22.010860101904218, -70.69804624387834],
  ],
  '02': [
    [-20.5010671999698, -66.24287456914281],
    [-27.01325527348009, -71.65179267278066],
  ],
  '03': [
    [-24.647017162630352, -67.52197265625001],
    [-29.96445285085199, -72.07031250000001],
  ],
  '04': [
    [-28.81915031689426, -69.02877238739188],
    [-32.74655074726989, -72.8534550573569],
  ],
  '05': [
    [-32, -70],
    [-34, -72],
  ],
  '06': [
    [-32.754942436547225, -69.95544433593751],
    [-36.27527883184337, -72.22961425781251],
  ],
  '07': [
    [-33.8891481408845, -69.8891801161583],
    [-37.33021884965413, -73.1053420628149],
  ],
  '08': [
    [-35.933852656495915, -70.82140097026165],
    [-39.28676559221805, -74.03756291691825],
  ],
  '09': [
    [-36.91521085897814, -70.66603082791109],
    [-40.224387001101796, -73.88219277456768],
  ],
  '10': [
    [-39.712856350131965, -71.00578226993419],
    [-45.01831722651866, -74.83046493989922],
  ],
  '11': [
    [-42.77394538012439, -70.75030632217437],
    [-49.78511009965117, -76.15922442581223],
  ],
  '12': [
    [-47.39260649891672, -64.24924394883298],
    [-56.79666265961051, -77.11389173545933],
  ],
  '13': [
    [-32.47732919639943, -69.58740234375001],
    [-34.74161249883172, -71.86157226562501],
  ],
  '14': [
    [-39, -71.6],
    [-41, -73.8],
  ],
  '15': [
    [-17.06959110121458, -68.87927419087964],
    [-19.934973324541485, -70.48735516420795],
  ],
  '16': [
    [-34.93548199355902, -70.84533691406251],
    [-38.14751758025121, -73.11950683593751],
  ],
};

export const requestParams = {
  getLastDeclared: {
    filter: 'filter[isActive]=1&sort=-creationTime',
    pageNumber: 1,
    pageSize: 3,
  },

  getLastCanceled: {
    filter: 'filter[isActive]=0&sort=-closureTime',
    pageNumber: 1,
    pageSize: 3,
  },

  filterByStatus: '[lastUpdate][status][name]',
  filterByZone: '[lastUpdate][locations][region][macrozone]',
  filterByRegion: '[lastUpdate][locations][region][cutRegion]',
  filterBySinapredId: '[sinapredId]',
  filterByLevel: '[lastUpdate][level][name]',
};

export const ROUTES: any = {
  home: {
    menuItemName: 'Inicio',
    tabItemName: 'Inicio',
    path: '/tabs/home',
  },
  map: {
    menuItemName: 'Mapa de Alertas',
    tabItemName: 'Mapa',
    path: '/tabs/mapa',
  },
  region: {
    path: '/regions/:regionId',
  },
  activeAlerts: {
    menuItemName: 'Vigentes',
    tabItemName: 'Vigentes',
    path: '/tabs/vigentes',
  },
  subscriptions: {
    menuItemName: 'Suscripciones',
    tabItemName: 'Suscripciones',
    path: '/tabs/suscripciones',
  },
  cancelledAlerts: {
    menuItemName: 'Canceladas',
    tabItemName: 'Canceladas',
    path: '/tabs/canceladas',
  },
  alertDetails: {
    path: '/alerts/:alertId',
  },
  eventDetails: {
    path: '/events/:eventId',
  },
  settings: {
    menuItemName: 'Configuración de Notificaciones',
    tabItemName: 'Configuración de Notificaciones',
    path: '/tabs/settings',
  },
  about: {
    menuItemName: 'Acerca de',
    tabItemName: 'Acerca de',
    path: '/tabs/about',
  },
  politica: {
    menuItemName: 'Politica de privacidad',
    tabItemName: 'Politica de privacidad',
    path: '/tabs/politica',
  },
  healthCheck: {
    path: '/tabs/health-check',
  },
  healthCheckNotifications: {
    path: '/tabs/health-check-notifications',
  },
  shared: {
    path: '/tabs/shared',
  },
  403: {
    path: '/tabs/403',
    header: 'Lo sentimos, pero no tienes permiso para acceder a esta página.',
    message:
      'La página que estás intentando visitar está restringida y no se te permite acceder a su contenido en este momento.',
  },
  400: {
    path: '/tabs/400',
    header: '¡Ups! Parece que hemos tenido un problema.',
    message:
      'Lo sentimos, pero no podemos encontrar el contenido que estás buscando.',
  },
  404: {
    path: '/tabs/404',
    header: '¡Ups! Parece que hemos tenido un problema.',
    message:
      'Lo sentimos, pero no podemos encontrar el contenido que estás buscando.',
  },
  500: {
    path: '/tabs/500',
    header: '¡Ups! Parece que hemos tenido un problema.',
    message:
      'Lo sentimos, pero no podemos encontrar el contenido que estás buscando.',
  },
  405: {
    path: '/tabs/405',
    header: '¡Ups! Parece que hemos tenido un problema.',
    message:
      'Lo sentimos, pero no podemos encontrar el contenido que estás buscando.',
  },
  406: {
    path: '/tabs/406',
    header: 'Lo sentimos, pero no tienes permiso para acceder a esta página. ',
    message:
      'La página que estás intentando visitar está restringida y no se te permite acceder a su contenido en este momento.',
  },
  error: {
    path: '/tabs/error',
    header: '¡Ups! Algo Salió Mal.',
    message:
      'Lo sentimos, pero algo salió mal mientras procesábamos tu solicitud. Nuestro equipo técnico ha sido notificado sobre este problema y trabajará para solucionarlo lo más pronto posible. Si el problema persiste, no dudes en contactarnos para obtener ayuda.',
  },
};

export const errorsRoutes = {
  400: {
    header: '¡Ups! Parece que hemos tenido un problema.',
    message: 'La solicitud que realizaste no pudo ser procesada correctamente.',
  },

  403: {
    header: 'Lo sentimos, pero no tienes permiso para acceder a esta página.',
    message:
      'La página que estás intentando visitar está restringida y no se te permite acceder a su contenido en este momento.',
  },
  404: {
    header: '¡Ups! Parece que hemos tenido un problema.',
    message:
      'Lo sentimos, pero no podemos encontrar el contenido que estás buscando.',
  },
  500: {
    header: 'Error Interno del Servidor.',
    message:
      'Ocurrió un error interno en el servidor mientras procesaba tu solicitud. Nuestro equipo técnico ha sido notificado sobre este problema y trabajará para solucionarlo lo más pronto posible.',
  },
};

// export const errorsRoutes = {
//   pathPrefix: '/tabs/',
//   paths: [400, 403, 404, 500],
//   header: '¡Ups! Parece que hemos tenido un problema.',
//   message:
//     'Lo sentimos, pero no podemos encontrar el contenido que estás buscando.',
// };

// Constructs a CardProps instance
// from data obtained through useGetAllAlertsQuery API call
// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const makeCardProps = (
  a: any,
  _pageName: string,
  filterListOfAlerts?: any,
  history?: any
) => {
  const cardProps: CardProps = {
    // eslint-disable-next-line @typescript-eslint/ban-types
    filterListOfAlerts: filterListOfAlerts as any,
    id: a.sinapredId,
    idInt: a.id,
    alert: 'Incendio forestal',
    type: a.lastUpdateData.status,
    lastUpdateId: a.lastUpdate.data.id,
    active: a.isActive,
    level: a.lastUpdateData.level,
    date: new Date(a.creationTime),
    dateUpd: new Date(a?.lastUpdateData.updateTime),
    alertLocationsData: a.lastUpdate.data.locations,
  };
  return cardProps;
};

export const maxNumLocationElementsInCard = 5;
export const fontForTextWidthMeasuring = '16px "Lato", sans-serif';

export const mapResolution = 1;

export const mapColors: any = {
  'Temprana Preventiva': '018484',
  Amarilla: 'ebbc46',
  Roja: 'e22c2c',
  Inactive: '0F69C4',
};

const backendProtocol = process.env.REACT_APP_BACKEND_PROTOCOL;
const backendUrl = process.env.REACT_APP_BACKEND_URL;
const backendApiBasePath = process.env.REACT_APP_BACKEND_API_BASE_PATH;

const mapUrl = process.env.REACT_APP_MAP_API_URL;
const mapAPiPath = process.env.REACT_APP_MAP_API_ADDRESS;

export const APIsToHealthCheck = [
  {
    name: 'API de Alertas',
    url: backendProtocol + '://' + backendUrl + '/' + backendApiBasePath,
    API: '/any',
    testParams: [],
    OkResponse: '200',
  },
  {
    name: 'API de mapas',
    url: backendProtocol + '://' + mapUrl,
    API: mapAPiPath,
    testParams: ['rlevel=1', 'cut=01101'],
    OkResponse: '200',
  },
];

// ids in the sort order
export const preferredResourcesIds = {
  'Incendio Forestal': [3, 1, 2, 30],
};

export const notPrefferedResourcesIds = [27, 33];
// 27 - otros
// 33 - en trayecto

export const makeGoBackUrl = (location: Location, sectionsToRemove: number) => {
  const path = location.pathname.split('/');
  path.splice(-sectionsToRemove, sectionsToRemove);
  return path.join('/');
};

export const alertLevels = ['Nacional', 'Regional', 'Provincial', 'Comunal'];

// first letter to capital
export const endpointsToHealthCheck = [
  {
    label: '',
    name: 'getAllAlerts',
    testParams: { filter: '', pageNumber: '1', pageSize: '5' },
    OkResponse: '200',
  },
  {
    label: '',
    name: 'getAllRegions',
    testParams: {},
    OkResponse: '200',
  },
  {
    label: '',
    name: 'getAllAlertsNoPagination',
    testParams: {},
    OkResponse: '200',
  },
  // {
  //   label: '',
  //   name: 'getAllCancelledAlertsWithIds',
  //   testParams: { idsToCheck: '' },
  //   OkResponse: '200',
  // },
  // {
  //   label: '',
  //   name: 'getAlertById',
  //   testParams: { alertId: '' },
  //   OkResponse: '200',
  // },
  {
    label: '',
    name: 'getAllAlertsByStatus',
    testParams: {},
    OkResponse: '200',
  },
  {
    label: '',
    name: 'getAllAlertsByZone',
    testParams: {},
    OkResponse: '200',
  },
  // {
  //   label: '',
  //   name: 'getAllActiveAlertsByRegionAndType',
  //   testParams: { cutRegion: '', alertType: '' },
  //   OkResponse: '200',
  // },
  // {
  //   label: '',
  //   name: 'getResources',
  //   testParams: { filter: '', value: '' },
  //   OkResponse: '200',
  // },
  // {
  //   label: '',
  //   name: 'getAllAlertLocations',
  //   testParams: { id: '' },
  //   OkResponse: '200',
  // },
  // {
  //   label: '',
  //   name: 'getAlertEvents',
  //   testParams: { alertId: '' },
  //   OkResponse: '200',
  // },
  // {
  //   label: '',
  //   name: 'getAlertEventsDetails',
  //   testParams: { alertId: '' },
  //   OkResponse: '200',
  // },
  {
    label: '',
    name: 'getAlertUpdates',
    testParams: { alertId: '' },
    OkResponse: '200',
  },
  // {
  //   label: '',
  //   name: 'getEventById',
  //   testParams: { eventId: '' },
  //   OkResponse: '200',
  // },
  // {
  //   label: '',
  //   name: 'getNotificationPlatforms',
  //   testParams: { url: '/notification/list_platforms', method: 'POST' },
  //   OkResponse: '200',
  // },
  // {
  //   label: '',
  //   name: 'snsCreateEndpoint',
  //   testParams: {
  //     token: '',
  //     platformApplicationARN: '',
  //     customUserData: {
  //       user_id: 'test',
  //       user_name: 'test',
  //     },
  //     overwrite: false,
  //   },
  //   OkResponse: '200',
  // },
  // {
  //   label: '',
  //   name: 'snsGetEndpointStatus',
  //   testParams: { endpointARN: '' },
  //   OkResponse: '200',
  // },
  // {
  //   label: '',
  //   name: 'snsConfigEndpoint',
  //   testParams: {
  //     endpointARN: '',
  //     token: '',
  //     enabled: true,
  //     customUserData: '',
  //   },
  //   OkResponse: '200',
  // },
  // {
  //   label: '',
  //   name: 'snsSubscribeTopic',
  //   testParams: { topicARN: '', endpoint: '', filters: '' },
  //   OkResponse: '200',
  // },
  // {
  //   label: '',
  //   name: 'snsUnsubscribeTopic',
  //   testParams: { topicARN: '', endpoint: '' },
  //   OkResponse: '200',
  // },
  // {
  //   label: '',
  //   name: 'getStaticTopics',
  //   testParams: {},
  //   OkResponse: '200',
  // },
];

// newety alert threshold in seconds
// used for testing locally
export const newAlertThresholdTest = 600000;

///////////////////////
// map options ////////
///////////////////////
// region map /////////
export const initialMapCoords = [-38.437954004569356, -70.65932705697341];
export const minZoom = 4;
export const maxZoom = 12;

export const tileLayer =
  'https://server.arcgisonline.com/ArcGIS/rest/services/Canvas/World_Light_Gray_Base/MapServer/tile/{z}/{y}/{x}';
export const attribution =
  '&copy; <a href="https://esri.maps.arcgis.com/">Esri</a>';
///////////////////////

export const mapRegionFillOpacity = 0.2;
export const mapRegionWeight = 0.5;
export const showMapRegional = true;

export const mapRedColor = '#E22C2C';
export const mapRegionBorderColorRed = '#880A0A';

export const mapYellowColor = '#EBBC46';
export const mapRegionBorderColorYellow = '#815025';

export const mapGreenColor = '#30B677';
export const mapRegionBorderColorGreen = '#3E6946';

export const mapRegionFillColor = {
  roja: mapRedColor,
  amarilla: mapYellowColor,
  'temprana preventiva': mapGreenColor,
};

export const mapCountryBorderColor = '#79848B';
export const mapRegionBorderColor = 'rgb(61, 73, 126)';

export const mapSecondaryColor = '#DAE3FB';
export const mapBlueGrayColor = '#79848B';

export const mapInitTimeOut = 250;
export const mapTooltipsInitTimeOut = 250;

export const notificationsGuides = [
  {
    title: 'En una aplicación',
    items: [
      {
        title: 'Android',
        link: 'https://support.google.com/android/answer/9079661?hl=es',
        label: 'Ir a Android notificaciónes guia',
      },
      {
        title: 'iOS',
        link: 'https://support.apple.com/es-cl/guide/iphone/iph7c3d96bab/ios',
        label: 'Ir a iOS notificaciónes guia',
      },
    ],
  },

  {
    title: 'En un navegador',
    items: [
      {
        title: 'Google Chrome',
        link: 'https://support.google.com/chrome/answer/3220216?hl=es&co=GENIE.Platform%3DDesktop',
        label: 'Ir a Google Chrome notificaciónes guia',
      },
      {
        title: 'Microsoft Edge | Sólo para Windows',
        link: 'https://support.microsoft.com/es-es/microsoft-edge/administrar-notificaciones-de-sitios-web-en-microsoft-edge-0c555609-5bf2-479d-a59d-fb30a0b80b2b#:~:text=In%20Edge%2C%20go%20to%20Settings,are%20currently%20sending%20you%20notifications.',
        label: 'Ir a Microsoft Edge notificaciónes guia',
      },
      {
        title: 'Safari | Sólo para MacOS',
        link: 'https://support.apple.com/es-cl/guide/safari/sfri40734/mac',
        label: 'Ir a Safari notificaciónes guia',
      },
    ],
  },
];

export const swipeConfig = {
  delta: 10, // min distance(px) before a swipe starts
  preventScrollOnSwipe: true, // prevents scroll during swipe
  trackTouch: true, // track touch input
  trackMouse: false, // track mouse input
  rotationAngle: 0, // set a rotation angle
  swipeDuration: Infinity, // allowable duration of a swipe (ms)
  touchEventOptions: { passive: true }, // options for touch listeners
};

export const maxPixelsNumberFromEdge = 20;

export const iframeDataPostProcessing = (ev: any) => {
  const new_style_element = document.createElement('style');
  new_style_element.textContent =
    'body { padding: 0 !important; padding-left: 20px !important; padding-right: 20px !important; } body>div{display: none} body>p:first-of-type{display: none}';
  (ev.target as any)?.contentDocument.head.appendChild(new_style_element);

  // document.frames[iframe].getElementsByTagName('a')[0].target = '_blank';
  const linkEl = (ev.target as any)?.contentDocument.querySelectorAll('a');
  for (let i = 0; i < linkEl.length; i++) {
    linkEl[i].setAttribute('target', '_blank');

    const link = linkEl[i].getAttribute('href');

    if (link?.includes('https://www.google.com/url?q=')) {
      const newLink = link
        .replace('https://www.google.com/url?q=', '')
        .split('&sa=D')[0];
      linkEl[i].setAttribute('href', newLink);
    }
  }

  const bodyEl = (ev.target as any)?.contentDocument.querySelectorAll(
    'body'
  )[0];
  const footerEl = document.createElement('div');
  footerEl.style.cssText =
    'width:100%;display:flex;justify-content:center;gap:40px;margin-top:50px;margin-bottom:50px;align-items:center;';

  const imgElLeft = document.createElement('img');
  imgElLeft.src = '/assets/itrend-logo-blue.svg';

  const imgElRight = document.createElement('img');
  imgElRight.src = SenapredLogo;
  imgElRight.style.cssText = 'height:auto;';

  bodyEl.appendChild(footerEl);

  footerEl.appendChild(imgElLeft);
  footerEl.appendChild(imgElRight);
};
